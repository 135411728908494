import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import AdBanner from "../../components/ad-banner";
const markdown = require( "markdown" ).markdown;

var tempAudio = null;

class View extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			text: "",
			errorMessage: ""
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	handleChange(event) {
		if(event.target.id === 'textarea'){
			this.setState({ text: event.target.value });
		}
	}

	handleClick(event) {
		if(event.target.id === 'generate-html') {
			let value = this.state.text;
			console.log(value);
			console.log( markdown.toHTML( value ) );
		}	
	}

	getDangerousHTML = str => {
		return { __html: str };
	};

	render() {
		return (
			<Layout>
				<SEO
					title="Markdown Editor"
					keywords={[
					]}
					description={
						"This tool helps you keep spaces and linebreaks in Instagram Captions, Tinder Bios etc. Showcase your text exactly the way you want to."
					}
				/>
				{/* <Navigation /> */}
				{/* <AdBanner></AdBanner> */}
				<Container>
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col>
							<h3
								style={{
									textAlign: "center",
									fontWeight: "300"
								}}
							>
								<Link to="/products">
									{"⬅ Markdown Editor"}
								</Link>
							</h3>
						</Col>
					</Row>
				</Container>
				<Container
					style={{
						display: "flex",
						justifyContent: "center"
					}}
				>
					<div
						className="card-2"
						style={{
							padding: "1rem",
							backgroundColor: "white",
							width: "100%",
							maxWidth: 650
						}}
					>
						<Form.Group>
							<Form.Control
								id="textarea"
								as="textarea"
								rows="5"
								value={this.state.text}
								onChange={this.handleChange}
								placeholder={"Text goes here."}
							/>
						</Form.Group>
						<div style={{ marginTop: 20 }} />
						<Button
							id="generate-html"
							variant="primary"
							block
							onClick={this.handleClick}
						>
							Generate HTML
						</Button>
					</div>
				</Container>
				<div style={{ marginTop: 100 }} />
				<DonationFooter ad={false} />
			</Layout>
		);
	}
}

export default View;
